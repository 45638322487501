<template>
  <el-container>
    <patient-new-form v-model="showNewPatientForm" :patient_id="newPatientId" :gdt="newPatientGdt" @after-save="setPatientForSearchForm"></patient-new-form>
    <patient-form v-model="showPatientForm" :mode="showPatientFormMode"></patient-form>
    <contact-form v-model="showContactForm"></contact-form>
    <el-header style="background-color:#FFF;">
      <el-row class="pt-2 pb-2">
        <el-col :xs="11" :sm="9" :span="7" :xl="4" :lg="7">
          <el-button-group>

            <el-tooltip
                content="Kontakt / Supportanfrage"
                placement="bottom"
            >
              <el-button @click="showContactForm = true" icon="el-icon-chat-line-square" plain></el-button>
            </el-tooltip>

            <el-tooltip
                content="Einstellungen"
                placement="bottom"
            >
              <el-button @click="openWindow('setting')" icon="el-icon-setting" plain></el-button>
            </el-tooltip>

            <el-tooltip
                content="Patient aus AIS holen"
                placement="bottom"
            >
              <el-button @click="getPatientFromAIS" icon="el-icon-caret-bottom" plain></el-button>
            </el-tooltip>

            <el-tooltip
              :content="(currentPatient == null ? 'Patient hinzufügen' : 'Patient Stammdaten')"
              placement="bottom"
            >
              <el-button @click="onPatientIconClick" icon="el-icon-user-solid" plain></el-button>
            </el-tooltip>

            <el-tooltip
              content="Ausdruck"
              placement="bottom"
              v-if="currentPatient"
            >
              <el-button @click="openPdfWindow" icon="el-icon-printer" plain></el-button>
            </el-tooltip>

            <el-dropdown class="border-left" v-if="moduleButtonCount > 1" @command="openModuleWindow">
              <el-button icon="el-icon-document" plain>
                <i class="el-icon el-icon-arrow-down"></i>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="studies" v-if="modules_enabled.survey" >Studien</el-dropdown-item>
                  <el-dropdown-item command="cancerregistry" v-if="modules_enabled.cancerregistry" >Krebsregister</el-dropdown-item>
                  <el-dropdown-item command="knowledgebase" v-if="modules_enabled.knowledgebase" >Knowledgebase</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-tooltip
              v-else-if="modules_enabled.survey"
              content="Studien"
              placement="bottom"
            >
              <el-button @click="openModuleWindow('studies')" icon="el-icon-document" plain></el-button>
            </el-tooltip>
            <el-tooltip
              v-else-if="modules_enabled.cancerregistry"
              content="Krebsregister"
              placement="bottom"
            >
              <el-button @click="openModuleWindow('cancerregistry')" icon="el-icon-document" plain></el-button>
            </el-tooltip>
            <el-tooltip
              v-else-if="modules_enabled.knowledgebase"
              content="Knowledgebase"
              placement="bottom"
            >
              <el-button @click="openModuleWindow('knowledgebase')" icon="el-icon-document" plain></el-button>
            </el-tooltip>

          </el-button-group>
        </el-col>
        <el-col :xs="11" :sm="13" :span="11" :xl="8" :lg="10">
          <patient-search :patient_id="currentPatientId"></patient-search>
        </el-col>
        <el-col :xs="0" :sm="0" :span="4" :xl="10" :lg="5">
          &nbsp;
        </el-col>
        <el-col :sm="2" :span="2" class="text-right align-content-end">
          <main-menu></main-menu>
        </el-col>
      </el-row>
    </el-header>
    <div class="w-100" style="background-color:#f39200; height:8px;"></div>
    <el-main v-if="!currentPatient">
      <el-card class="box-card">
        <h3>Willkommen bei Dacy</h3>
        Bitte benutzen Sie das Suchfeld oben um die Daten eines Patienten einsehen zu können.<br>
        Alternativ klicken Sie einfach auf den Button daneben, um den aktuellen Patienten aus dem Praxis Programm zu wählen.
      </el-card>
    </el-main>
    <el-main v-if="currentPatient">

      <el-card class="box-card">

      <el-tabs type="card" id="dashboard-tabs" @click="tabClickIndex++">
        <el-tab-pane label="Dashboard">

          <el-row>
            <el-col :span="24">

              <el-card>
                <el-row>
                  <el-col :span="12">
                    <h3>Diagnosen</h3>
                  </el-col>
                  <el-col :span="12" class="text-right">

                    <el-button class="w-160px" @click="openWindow('diagnosis')" type="primary" size="mini" icon="el-icon-circle-plus">Diagnose</el-button>

                    <el-button class="w-160px" v-if="unusedFindings.length > 0" @click="openWindow('finding')" type="danger" size="mini" icon="el-icon-warning">Befunde</el-button>
                    <el-button class="w-160px" v-else @click="openWindow('finding')" type="primary" size="mini" icon="el-icon-circle-plus">Befunde</el-button>
                  </el-col>
                </el-row>

                <el-card shadow="never">
                  <patient-diagnosis></patient-diagnosis>
                </el-card>

                <div class="mt-4"></div>

                <el-row>
                  <el-col :span="5">
                    <h3>Therapien</h3>
                  </el-col>
                  <el-col :span="19" class="text-right">
                    <el-button class="w-160px" @click="openWindow('therapyfromdrug')" type="primary" size="mini" icon="el-icon-circle-plus">Protokoll</el-button>
                    <el-button class="w-160px" @click="openWindow('therapy')" type="primary" size="mini" icon="el-icon-circle-plus">Therapie</el-button>
                    <!-- <el-button class="w-160px" @click="openWindow('therapyconcept')" type="primary" size="mini" icon="el-icon-circle-plus">Behandlungskonzept</el-button> -->
                    <el-button class="w-160px" @click="openWindow('progress')" type="primary" size="mini" icon="el-icon-circle-plus">Verlauf</el-button>
                  </el-col>
                </el-row>

                <el-card shadow="never">
                  <patient-therapies></patient-therapies>
                </el-card>


                <div class="mt-4"></div>

                <el-row>
                  <el-col :span="24">

                    <el-row>
                      <el-col :span="18">
                        <h3>Nebenwirkungen / Komplikationen</h3>
                      </el-col>
                      <el-col :span="6" class="text-right">
                        <el-button  @click="openWindow('sideeffects')" type="primary" size="mini" icon="el-icon-circle-plus">Nebenwirkung</el-button>
                      </el-col>
                    </el-row>

                    <el-card shadow="never">
                      <patient-side-effects></patient-side-effects>
                    </el-card>

                  </el-col><!--
              <el-col :offset="1" :span="8">

                            <el-row>
                              <el-col :span="12">
                                <h3>ECOG</h3>
                              </el-col>
                              <el-col :span="12" class="text-right">
                                <el-button @click="openWindow('ecog')" type="primary" size="mini" icon="el-icon-circle-plus">ECOG hinzufügen</el-button>
                              </el-col>
                            </el-row>
                            <el-card shadow="never">
                              <patient-ecog></patient-ecog>
                            </el-card>

                          </el-col> -->
                </el-row>


              </el-card>

            </el-col>
          </el-row>

        </el-tab-pane>
        <el-tab-pane label="Karteikarten">
          <patient-file-card :reload="tabClickIndex"></patient-file-card>
        </el-tab-pane>
        <el-tab-pane label="Bewertungen">
          <patient-evaluation :reload="tabClickIndex"></patient-evaluation>
        </el-tab-pane>
      </el-tabs>

      </el-card>

    </el-main>
  </el-container>
</template>

<script>
import PatientTherapies from "@/components/PatientTherapies";
import PatientSideEffects from "@/components/PatientSideEffects";
// import PatientEcog from "@/components/PatientEcog";
import PatientNewForm from "@/components/patient/PatientNewForm";
import PatientForm from "@/components/patient/PatientForm";
import ContactForm from "@/components/parts/ContactForm";
import PatientFileCard from "@/components/patient/PatientFileCard";
import PatientEvaluation from "@/components/patient/PatientEvaluation";

import {mapActions, mapGetters} from "vuex";
import socket from './../socket'
import axios from "axios";
import DacyServer from "@/dacyserver";
import MainMenu from "@/components/parts/MainMenu";

export default {
  name: "Dashboard",
  props: [],
  components: {
    MainMenu,
    PatientSideEffects,
    PatientTherapies,
    // PatientEcog,
    PatientNewForm,
    PatientForm,
    ContactForm,
    PatientFileCard,
    PatientEvaluation
  },
  data () {
    return {
      tabClickIndex:0,
      currentPatientId: null,
      newPatientId: null,
      showNewPatientForm: false,
      showPatientForm: false,
      showContactForm: false,
      surveyModuleEnabled: false,
      cancerRegisterModuleEnabled: false,
      newPatientGdt: null
    }
  },
  mounted: function () {
    socket.on('patient-changed', async () => {
      this.reloadCurrentPatient()
    })

    let uri = window.location.search
    let UrlParams = new URLSearchParams(uri)
    const patient_id = parseInt(UrlParams.get("patient"));
    if(!isNaN(patient_id) && patient_id > 0) {
      this.setCurrentPatient(patient_id)
    }

  },
  computed: {
    ...mapGetters('patients', ['currentPatient', 'unusedFindings']),
    ...mapGetters('config', ['modules_enabled']),
    showPatientFormMode: function () {
      return (this.currentPatient == null ? 'new' : 'current')
    },
    moduleButtonCount: function () {
      let count = 0
      if(this.modules_enabled.knowledgebase) count++
      if(this.modules_enabled.cancerregistry) count++
      if(this.modules_enabled.survey) count++
      return count
    }
  },
  watch: {
  },
  methods: {
    ...mapActions('patients', ['reloadCurrentPatient', 'setCurrentPatient']),
    openWindow(path) {
      if(path === 'setting') {
        this.$root.openNewWindow('/app/setting')
      }
      else if(this.currentPatient) {
        this.$root.openNewWindow('/app/patient/' + this.currentPatient.id + '/' + path)
      }
    },
    openModuleWindow(command) {
      if(command === 'studies') {
        window.open('/app/studies', '_blank', 'top=' + (window.screenY + 50) +',left=' + (window.screenX + 50))
      }
      if(command === 'cancerregistry') {
        window.open('/app/cancerregistry', '_blank', 'top=' + (window.screenY + 50) +',left=' + (window.screenX + 50))
      }
      if(command === 'knowledgebase') {
        window.open('/app/knowledgebase', '_blank', 'top=' + (window.screenY + 50) +',left=' + (window.screenX + 50))
      }
    },
    async openPdfWindow() {
      if(this.currentPatient) {
        // get signed url
        const signResult = await axios.get('/sign/export/patients/pdf/' + this.currentPatient.id)
        if(signResult) {
          const pdfUrl = DacyServer.getInstance().getURL() + signResult.data
          window.open(pdfUrl, '_blank', 'top=' + (window.screenY + 50) +',left=' + (window.screenX + 50))
        }
      }
    },
    async getPatientFromAIS() {
      const result = (await axios({ url: 'gdt', baseURL: 'http://127.0.0.1:3333' })).data
      if(result.error === false) {
        const patient = await this.setCurrentPatient(result.patient_id)
        if(patient === null) {
          this.newPatientId = result.patient_id
          this.newPatientGdt = result.gdt
          this.showNewPatientForm = true
        }
        else {
          this.currentPatientId = result.patient_id
        }
      }
      else {
        this.$notify.error({
          message: "Ein Fehler trat bei der Kommunikation mit dem AIS auf",
          position: 'bottom-left'
        });
      }

    },
    setPatientForSearchForm() {
      this.currentPatientId = this.currentPatient.id
    },
    onPatientIconClick() {
      this.showPatientForm = true
    }
}

}
</script>
<style>
#dashboard-tabs .el-tabs__header {
  margin-bottom: 0px !important;
  border-bottom: none !important;
}
</style>
